import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { VerifiedWrapper } from '@pages/NewUserSettings/components/Layout';
import CustomSelect from '@components/CustomSelect';
import DotsMenu, { DOTS_MENU_ITEM_TYPES, getDotsMenuOptions } from '@components/DotsMenu';
import FormBlock from '@components/FormBlock';
import ListItemWithColorIndicator from '@components/ListItemWithColorIndicator';
import Table, { TypeSelectedRow } from '@components/Table';
import UserItem from '@components/NewUserItem';
import VerifiedEmailButton from '@pages/NewUserSettings/components/ProfileInformation/VerifiedEmailButton';

import { COLORS } from '@constants/colors';
import { getIsAbleToDeleteUser } from '@pages/NewUserSettings/utils';
import { Roles } from '@constants/common';
import { USER_STATUS_OPTIONS } from '@pages/NewUserSettings/data';
import { USER_STATUSES_COLOR_MAP } from './data';

import { StatusSelectWrapper, TextEllipsis } from './styles';

import { UsersRowProps } from './types';
import { ValueLabelObj } from '@/shared/types/commonTypes';
import { GetDotsMenuOptionProps } from '@components/DotsMenu/types';

const DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE = 'user-name';

export const UsersRow = observer(({
  dataItem,
  isProfilePanelOpened,
  onDeleteUserClick,
  onViewOrEditClick,
  selectedRowId
}: UsersRowProps) => {

  const isAbleRole = getIsAbleToDeleteUser(dataItem.userRole[0] as Roles);

  const menuOptions = useMemo(() => {
    const menuDotsConfig: GetDotsMenuOptionProps = {
      arrayOfTypes: [ DOTS_MENU_ITEM_TYPES.viewOrEdit ],
    };
    if (isAbleRole) {
      menuDotsConfig.arrayOfTypes.push(DOTS_MENU_ITEM_TYPES.delete);
      menuDotsConfig.withTopSeparators = [ DOTS_MENU_ITEM_TYPES.delete ];
    }
    return getDotsMenuOptions(menuDotsConfig);
  }, [ isAbleRole ]);

  const onViewOrEditClickWrapper = useCallback(() => {
    onViewOrEditClick(dataItem.id);
  }, [dataItem.id, onViewOrEditClick]);

  const onUserProfilePanelClick = useCallback(() => {
    if(isProfilePanelOpened) {
      onViewOrEditClickWrapper();
    }
  }, [isProfilePanelOpened, onViewOrEditClickWrapper]);

  const onMenuSelect = useCallback((action: string) => {
    if (action === DOTS_MENU_ITEM_TYPES.viewOrEdit) {
      onViewOrEditClickWrapper();
    }

    if (action === DOTS_MENU_ITEM_TYPES.delete) {
      onDeleteUserClick(dataItem);
    }
  }, [ dataItem, onDeleteUserClick, onViewOrEditClickWrapper ]);

  const memoVerifiedBlock = useMemo(() => {
    return (
      <VerifiedEmailButton
        emailStatus={ dataItem.emailVerified }
        disabled={ true }
      />
    );
  }, [ dataItem.emailVerified ]);

  return (
    <Table.Row
      $backgroundColor={ COLORS.select }
      $preventHover={ false }
      $typeSelectedRow={ TypeSelectedRow.Solid }
      hover
      onDoubleClick={ onUserProfilePanelClick }
      selected={ selectedRowId === dataItem.id }
      tabIndex={ -1 }
    >
      <Table.Cell
        dataCustomStyleAttributeValue={ DATA_CUSTOM_STYLE_ATTRIBUTE_VALUE }
        onClick={ onUserProfilePanelClick }
      >
        <UserItem
          labelWidth={ 250 }
          user={ dataItem }
        />
      </Table.Cell>

      <Table.Cell>
        <TextEllipsis>
          { dataItem.email }
        </TextEllipsis>
      </Table.Cell>

      <Table.Cell>
        <VerifiedWrapper>
          <FormBlock.FormField
            label={ '' }
          >
            { memoVerifiedBlock }
          </FormBlock.FormField>
        </VerifiedWrapper>
      </Table.Cell>


      <Table.Cell>{ dataItem.userRole }</Table.Cell>

      <Table.Cell>
        <StatusSelectWrapper>
          <CustomSelect
            disabled
            onChange={ () => {} }
            options={ USER_STATUS_OPTIONS }
            renderItem={ (option) => {
              const { label } = option as ValueLabelObj;
              return (
                <ListItemWithColorIndicator
                  indicatorColor={ USER_STATUSES_COLOR_MAP[dataItem.status] }
                  label={ label }
                />
              ); }
            }
            value={ dataItem.status }
          />
        </StatusSelectWrapper>
        {
          isProfilePanelOpened &&
          <DotsMenu
            $marginLeft={ 'auto' }
            $marginRight={ '10px' }
            items={ menuOptions }
            onSelect={ onMenuSelect }
          />
        }
      </Table.Cell>
    </Table.Row>
  );
});
